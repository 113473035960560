import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import firebase from 'firebase';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { fromEvent } from 'rxjs';
import { fadeAnimation } from 'src/app/utils/animations';
import { AllToursFunction, runFunction } from 'src/app/utils/functions';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrl: './search.component.css',
  animations: [fadeAnimation]
})
export class SearchComponent implements OnInit {

  results = []
  searchDebounced
  loading = false
  query = ''

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.setupSearch()
  }

  setupSearch() {
    this.searchDebounced = fromEvent(document.getElementById('searchInput'), 'input')
      .pipe(
        map((event: any) => {
          this.results = []
          this.query = event.target.value.length
          this.loading = event.target.value.length > 2
          return event.target.value
        }),
        debounceTime(500),
        distinctUntilChanged(),
        filter(query => query.length > 2),
      );

    this.searchDebounced.subscribe(query => {
      console.log('Debounced search query:', query);
      this.search(query);
    });
  }

  async search(query) {
    this.results = await runFunction(AllToursFunction.searchAll, { query: query })
    this.loading = false
  }

}


