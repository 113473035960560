<div class="container" *ngIf="partnerService.partner$ | async as partner">
  <div class="row">
    <div class="col-12 borderTop" style="padding-bottom: 32px;">
      <h5>Your Link & QR Code</h5>
      <p class="secondaryText">This link will send people straight to the home page of alltours.app.</p>
    </div>
  </div>
  <div class="row topCardHolder" style="margin-top: 16px;">
    <div class="col-12 col-lg-6">
      <div>
        <div class="input-group mb-3" style="max-width: 350px;">
          <input value="{{partner.marketing.link.replace('https://','')}}" type="text" class="form-control" readonly />
          <button class="btn btn-outline-success" type="button" id="button-addon2"
            (click)="copyLink(partner.marketing.link)">
            <span class="material-symbols-rounded icon">content_copy</span>
          </button>
        </div>
        <p class="secondaryText">Sales through this link will be attributed to you.</p><br />
      </div>
    </div>
    <div class="col-12 col-lg-6">
      <div style="display: flex;">
        <img src="{{ partner.marketing.qrCode }}" class="qrCode" />
        <div>
          <p class="secondaryText">
            You can place this QR code on your website or print it onto your own marketing material.
          </p>
          <div style="margin-top: 16px;">
            <a href="{{ partner.marketing.qrCode }}" download="AllToursPartnerQrCode.svg" target="_blank">
              <button class="btn btn-outline-success" type="button">
                Download QR Code
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="partner.partnerType != 'individual'">
    <div class="col-12 borderTop" style="padding-bottom: 32px;">
      <h5>Posters</h5>
      <p class="secondaryText">Print posters out and place them where people will see them. The poster QR code will
        attribute sales to you.</p>
    </div>
    <div class="col-12 col-md-6 col-lg-4 assetContainer" *ngFor="let asset of assets" routerLink="poster"
      [queryParams]="{ id: asset.id }">
      <img src="{{ asset.image }}" class="assetImage" />
      <br />
      <div class="assetTitle">{{ asset.title }}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 borderTop" style="padding-bottom: 32px;">
      <h5>Images</h5>
      <p class="secondaryText">Use images in conjunction with your link to direct people to the AllTours website.</p>
    </div>
    <div class="col-12 col-md-4 col-lg-3 assetContainer" *ngFor="let i of [1,2,3,4,5,6,7,8]">
      <a href="../../../../../assets/images/alltours/partnerAssets/image_{{i}}.jpg"
        download="AllToursPartnerImage{{i}}.jpg" target="_blank">
        <img src="../../../../../assets/images/alltours/partnerAssets/image_{{i}}.jpg" class="assetImage" />
      </a>
      <br />
      <div class="assetTitle">Image {{i}}</div>
    </div>
  </div>
</div>