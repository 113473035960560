<div style="display: flex">
  <div *ngIf="tour != undefined" style="position: absolute; width: 100%">
    <img class="backgroundImg" src="{{ tour.image != undefined ? tour.image.large : '' }}"
      onerror="this.onerror=null; this.src='../../../assets/images/alltours/placeholder.png'" />
  </div>
  <div class="imgCover"></div>
  <div class="container ontopCover">
    <div class="row">
      <nav class="col-12" aria-label="breadcrumb">
        <ol class="breadcrumb" style="background-color: #0000">
          <li class="breadcrumb-item">
            <a class="bread-link text-capitalize" routerLink="">{{ strings.home }}</a>
          </li>
          <li class="breadcrumb-item">
            <a class="bread-link text-capitalize" routerLink="/tour/{{ countryName.toLowerCase() }}">
              {{ countryName }}
            </a>
          </li>
          <li class="breadcrumb-item">
            <a class="bread-link text-capitalize"
              routerLink="/tour/{{ countryName.toLowerCase() }}/{{ cityName.toLowerCase() }}">
              {{ cityName }}
            </a>
          </li>
          <li class="breadcrumb-item" aria-current="page">
            {{ strings.tour }}
          </li>
        </ol>
      </nav>
    </div>
    <div style="height: 90vh; width: 100%" *ngIf="loading && !tourNotFound">
      <div class="loader" style="position: absolute; top: 50%; right: 50%"></div>
    </div>
    <div *ngIf="tourNotFound">
      <div class="card" style="margin-top: 50%;">
        <p>Sorry, we couldn't find the tour you were looking for 😭</p>
        <p>Please checkout the
          <a class="text-capitalize" routerLink="/tour/{{ countryName.toLowerCase() }}/{{ cityName.toLowerCase() }}">
            {{ cityName }}
          </a>
          page for more tours in this city.
        </p>
      </div>
    </div>
    <div *ngIf="!loading" class="cityLanding row">
      <div>
        <div class="col-12">
          <h3 class="text-center">{{ tour.title }}</h3>
        </div>
        <div class="col-12" *ngIf="tour.ratings.ratingCount > 0 && tour.ratings.ratingAvg > 1">
          <div class="tourRating">
            <div style="color: #ccc">
              <span class="fa fa-star" [ngClass]="{ checked: tour.ratings.ratingAvg >= 0.5 }"></span>
              <span class="fa fa-star" [ngClass]="{ checked: tour.ratings.ratingAvg >= 1.5 }"></span>
              <span class="fa fa-star" [ngClass]="{ checked: tour.ratings.ratingAvg >= 2.5 }"></span>
              <span class="fa fa-star" [ngClass]="{ checked: tour.ratings.ratingAvg >= 3.5 }"></span>
              <span class="fa fa-star" [ngClass]="{ checked: tour.ratings.ratingAvg >= 4.5 }"></span>
            </div>
            <div style="margin-left: 4px">
              <span class="tourRatingCount">{{ tour.ratings.ratingCount }} Reviews</span>
            </div>
          </div>
        </div>
        <div style="display: flex" class="col-12" *ngIf="tourOwnershipState == TourOwnershipState.NOT_OWNED">
          <button style="margin-left: auto; margin-right: 8px" type="button" (click)="playAudio()"
            class="ctaBtnMini2 tonal">
            <i *ngIf="playingState == 0" class="fa fa-play-circle icons"></i>
            <i *ngIf="playingState == 1" class="fa fa-spinner icons"></i>
            <i *ngIf="playingState == 2" class="fa fa-stop-circle icons"></i>
            <span style="margin-left: 4px">
              {{ strings.preview }}
            </span>
          </button>
          <div style="margin-right: auto; margin-left: 8px">
            <ng-template [ngTemplateOutlet]="buyTourButton"></ng-template>
          </div>
        </div>
        <div *ngIf="tourOwnershipState == TourOwnershipState.PURCHASED" (click)="goToApp()">
          <div style="padding: 8px; color: #fffd">
            You own this tour
          </div>
          <button type="button" class="ctaBtnMini2">
            Open App
          </button>
        </div>
        <div *ngIf="tourOwnershipState == TourOwnershipState.PARTNER_IN_CITY" (click)="goToApp()">
          <div style="padding: 8px; color: #fffd">
            As a partner in this city, you can listen to all the stops on the tour.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!loading" style="background: #fafafa; color: #000000aa; padding-bottom: 128px;">
  <div class="container card" id="article" style="max-width: 1000px;">
    <div class="row" style="margin: 0; min-height: 100vh; padding: 0px">
      <div class="col-12" *ngIf="stops != undefined"
        style="padding-bottom: 16px; padding-left: 0px; padding-right: 0px">
        <mgl-map class="mapDisplay" [style]="'mapbox://styles/kes-at-explorer/cldkmxdfq007m01lfqiqbiskp'"
          [interactive]="false" [zoom]="[1]" [fitBounds]="bounds" [fitBoundsOptions]="{ padding: 32 }">
          <mgl-layer id="routeOutline" type="line" [source]="{
              type: 'geojson',
              data: {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'LineString',
                  coordinates: line,
                },
              },
            }" [layout]="{
              'line-join': 'round',
              'line-cap': 'round',
            }" [paint]="{
              'line-color': '#000',
              'line-width': 8,
            }">
          </mgl-layer>
          <mgl-layer id="route" type="line" [source]="{
              type: 'geojson',
              data: {
                type: 'Feature',
                properties: {},
                geometry: {
                  type: 'LineString',
                  coordinates: line,
                },
              },
            }" [layout]="{
              'line-join': 'round',
              'line-cap': 'round',
            }" [paint]="{
              'line-color': '#FD6D6A',
              'line-width': 4,
            }">
          </mgl-layer>
          <mgl-marker *ngFor="let feature of stops.features; let i = index" [feature]="feature">
            <div class="marker" [ngStyle]="{ 'background-color': '#FD6D6A' }">
              <span>{{ i + 1 }}</span>
            </div>
          </mgl-marker>
        </mgl-map>
      </div>
      <div class="col-sm-12 col-md-6" style="color: #0008;">
        <p [innerHtml]="description"></p>
      </div>
      <div class="col-sm-12 col-md-6" style="margin-bottom: 16px">
        <div class="infoContainer">
          <div><i class="fa fa-headphones infoIcon"></i></div>
          <p style="margin-bottom: 0px">
            This tour contains <b>{{ tour.totalAudioDuration / 60 | number: '1.0-0' }} minutes</b> of audio.
          </p>
        </div>
        <div class="infoContainer">
          <div><i class="fa fa-map-marker infoIcon"></i></div>
          <p style="margin-bottom: 0px">
            The route is <b>{{ tour.routeInstructions.distance / 1000 | number: '1.1-1' }} km</b> in length.
          </p>
        </div>
        <div class="infoContainer">
          <div><i class="fa fa-clock-o infoIcon"></i></div>
          <p style="margin-bottom: 0px">
            Overall it should take you
            <b>{{ (tour.routeInstructions.duration + tour.totalAudioDuration) / 60 | number: '1.0-0' }} minutes</b> to
            complete.
          </p>
        </div>
      </div>
      <div class="col-sm-12" *ngIf="tour.ratings.ratingCount > 0" style="margin-bottom: 32px;">
        <div style="text-align: center;">
          <h1 style="margin-bottom: 0; font-size: 32px;">{{ tour.ratings.ratingAvg | number: '1.1-1' }}</h1>
          <div style="font-size: 28px; margin-bottom: 4px">
            <span class="fa fa-star" [ngClass]="{ checked: tour.ratings.ratingAvg >= 0.5 }"></span>
            <span class="fa fa-star" [ngClass]="{ checked: tour.ratings.ratingAvg >= 1.5 }"></span>
            <span class="fa fa-star" [ngClass]="{ checked: tour.ratings.ratingAvg >= 2.5 }"></span>
            <span class="fa fa-star" [ngClass]="{ checked: tour.ratings.ratingAvg >= 3.5 }"></span>
            <span class="fa fa-star" [ngClass]="{ checked: tour.ratings.ratingAvg >= 4.5 }"></span>
          </div>
        </div>
        <div style="font-size: 14px; text-align: center;">
          <span style="color: #0008">{{ tour.ratings.ratingCount }} {{ strings.reviews }}</span>
          <a style="margin-left: 8px" data-bs-toggle="modal" data-bs-target="#reviewsModal">View all</a>
        </div>
        <!-- <div style="margin-top: 32px; margin-bottom: 0px">
          <app-tour-review [review]="tour.ratings.featuredReview"></app-tour-review>
        </div> -->
      </div>
      <div class="accordion accordion-flush" style="padding: 0">
        <div class="accordion-item"
          *ngIf="tourOwnershipState == TourOwnershipState.PURCHASED || tourOwnershipState == TourOwnershipState.PARTNER_IN_CITY">
          <h2 class="accordion-header" id="stopsHeader">
            <button class="accordion-button sectionHeader" type="button" data-bs-toggle="collapse"
              data-bs-target="#stopsContainer" aria-expanded="true" aria-controls="stopsContainer">
              Stops
            </button>
          </h2>
          <div id="stopsContainer" class="accordion-collapse collapse show" aria-labelledby="stopsHeader">
            <div class="accordion-body">
              <div *ngFor="let landmark of landmarks; let i = index;">
                <div class="landmark-item">
                  <div style="width: 32px;">
                    <div *ngIf="landmark.latLng != undefined" class="number-line-container">
                      <div class="circle">{{ i }}</div>
                      <div class="line"></div>
                    </div>
                  </div>
                  <div class="landmark-details">
                    <b>{{ landmark.title }}</b>
                    <p class="secondaryText">{{ landmark.description }}</p>
                    <p class="secondaryText" *ngIf="landmark.duration != undefined">
                      <i class="fa fa-headphones"></i> {{ landmark.duration | formatTime }}
                    </p>
                  </div>
                  <div class="thumbnail thumbnailPlayContainer">
                    <img *ngIf="landmark.thumbnail != undefined" src="{{ landmark.thumbnail }}"
                      style="position: absolute;" />
                    <div class="imgPlayButtonContainer">
                      <button (click)="playAudio(landmark.audioUrl)" class="imgPlayButton">
                        <i *ngIf="currentUrlPlaying != landmark.audioUrl" class="fa fa-play"></i>
                        <div *ngIf="currentUrlPlaying == landmark.audioUrl">
                          <i *ngIf="playingState == 0" class="fa fa-play icons"></i>
                          <i *ngIf="playingState == 1" class="fa fa-spinner icons"></i>
                          <i *ngIf="playingState == 2" class="fa fa-stop icons"></i>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="thingsLoveHeader">
            <button class="accordion-button collapsed sectionHeader" type="button" data-bs-toggle="collapse"
              data-bs-target="#thingsLoveContainer" aria-expanded="false" aria-controls="thingsLoveContainer">
              Things you'll love
            </button>
          </h2>
          <div id="thingsLoveContainer" class="accordion-collapse collapse show" aria-labelledby="thingsLoveHeader">
            <div class="accordion-body">
              <div style="font-weight: bold" class="row">
                <div class="col-6 benefitsHolder" *ngFor="let step of allToursBenefits">
                  <div>
                    <span class="material-symbols-rounded icon">{{ step.icon }}</span><br />
                    <b class="primaryText">{{ step.title }}</b>
                    <p class="secondaryText">{{ step.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="howItWorksHeader">
            <button class="accordion-button collapsed sectionHeader" type="button" data-bs-toggle="collapse"
              data-bs-target="#howItWorksContainer" aria-expanded="false" aria-controls="howItWorksContainer">
              How it works
            </button>
          </h2>
          <div id="howItWorksContainer" class="accordion-collapse collapse" aria-labelledby="howItWorksHeader">
            <div class="accordion-body">
              <div style="font-weight: bold">
                <div *ngFor="let step of howItWorks" style="display: flex; padding-top: 12px; padding-bottom: 12px;">
                  <span class="material-symbols-rounded icon">{{ step.icon }}</span>
                  <div style="margin-left: 12px;">
                    <b class="primaryText">{{ step.title }}</b>
                    <p class="secondaryText">{{ step.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item" *ngIf="tourOwnershipState == TourOwnershipState.NOT_OWNED">
          <h2 class="accordion-header" id="stopsHeader">
            <button class="accordion-button sectionHeader" type="button" data-bs-toggle="collapse"
              data-bs-target="#stopsContainer" aria-expanded="true" aria-controls="stopsContainer">
              Stops
            </button>
          </h2>
          <div id="stopsContainer" class="accordion-collapse collapse" aria-labelledby="stopsHeader">
            <div class="accordion-body">
              <div *ngFor="let landmark of landmarks; let i = index;">
                <div class="landmark-item">
                  <div style="width: 32px;">
                    <div *ngIf="landmark.latLng != undefined" class="number-line-container">
                      <div class="circle">{{ i }}</div>
                      <div class="line"></div>
                    </div>
                  </div>
                  <div class="landmark-details">
                    <b>{{ landmark.title }}</b>
                    <p class="secondaryText">{{ landmark.description }}</p>
                    <p class="secondaryText" *ngIf="landmark.duration != undefined">
                      <i class="fa fa-headphones"></i> {{ landmark.duration | formatTime }}
                    </p>
                  </div>
                  <div *ngIf="landmark.thumbnail != undefined" class="thumbnail">
                    <img src="{{ landmark.thumbnail }}" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="faqsHeader">
            <button class="accordion-button collapsed sectionHeader" type="button" data-bs-toggle="collapse"
              data-bs-target="#faqsContainer" aria-expanded="false" aria-controls="faqsContainer">
              FAQs
            </button>
          </h2>
          <div id="faqsContainer" class="accordion-collapse collapse" aria-labelledby="faqsHeader">
            <div class="accordion-body">
              <ol style="padding-left: 1rem; font-weight: bold">
                <li *ngFor="let question of faqs" style="margin-bottom: 32px;">
                  <b class="primaryText">{{ question.title }}</b>
                  <p class="secondaryText" [innerHtml]="question.description"></p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <h4 style="margin-top: 64px;">Similar Tours</h4>
      </div>
      <div class="col-12 col-md-6 col-xl-4 tourPreviewOuterContainer" *ngFor="let tour of similarTours">
        <app-tour-preview [tour]="tour"></app-tour-preview>
      </div>
    </div>
  </div>
</div>
<div [@slideInOut]="showingBottomBar ? 'in' : 'out'" class="bottomButtonContainer">
  <div style="display: flex; width: 100%" *ngIf="tourOwnershipState == TourOwnershipState.NOT_OWNED">
    <button style="margin-left: auto; margin-right: 8px; padding-left: 8px; padding-right: 8px; font-size: 28px"
      type="button" (click)="playAudio()" class="ctaBtnMini2 tonal">
      <i *ngIf="playingState == 0" class="fa fa-play-circle icons"></i>
      <i *ngIf="playingState == 1" class="fa fa-spinner icons"></i>
      <i *ngIf="playingState == 2" class="fa fa-stop-circle icons"></i>
    </button>
    <div style="max-width: 400px; margin-right: auto; width: 100%">
      <ng-template [ngTemplateOutlet]="buyTourButton"></ng-template>
    </div>
  </div>
  <div style="display: flex; width: 100%; justify-content: center; align-items: center;"
    *ngIf="tourOwnershipState == TourOwnershipState.PURCHASED">
    <p class="secondaryText" style="font-size: 13px; padding-right: 16px;">
      For the full AllTours experience, please open the AllTours app.
    </p>
    <button type="button" class="ctaBtnMini2" (click)="goToApp()">
      Open App
    </button>
  </div>
</div>
<div [innerHTML]="jsonLD"></div>

<div id="loginModal" class="modal" tabindex="-1" style="color: #000">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Please login to continue</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="color: #000a">
        <app-user-login (successfulLogin)="onSuccessfulLogin($event)"
          [loginMessage]="'To purchase this tour, please login first...'"></app-user-login>
      </div>
    </div>
  </div>
</div>

<div id="checkingOutModal" class="modal" tabindex="-1" style="color: #000">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">One moment...</h5>
        <button id="closeCheckoutModal" type="button" class="btn-close" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body" style="color: #000a; padding: 20%; text-align: center">
        <div *ngIf="!checkoutError">
          <div class="loader" style="margin: auto"></div>
          <br />
          Just taking you to the checkout...
        </div>
        <div *ngIf="checkoutError">
          😞<br />Sorry, something has gone wrong.<br />Please try again later.
        </div>
      </div>
    </div>
  </div>
</div>

<button id="openCheckingOutModalBtn" data-bs-toggle="modal" data-bs-target="#checkingOutModal"
  style="display: none; width: 1px; height: 1px"></button>

<ng-template #buyTourButton>
  <button *ngIf="!(userService.isLoggedIn$ | async) && localPrice != undefined" type="button" style="width: 100%"
    data-bs-toggle="modal" data-bs-target="#loginModal" class="ctaBtnMini2">
    {{ strings.get_tour }} ({{ localPrice.price }})
  </button>
  <button *ngIf="(userService.isLoggedIn$ | async) && localPrice != undefined" type="button" style="width: 100%"
    (click)="buyTour()" class="ctaBtnMini2">
    <div *ngIf="processingPurchase">
      <div class="loader"></div>
      Loading...
    </div>
    <div *ngIf="!processingPurchase">{{ strings.get_tour }} ({{ localPrice.price }})</div>
  </button>
</ng-template>

<div id="reviewsModal" class="modal" tabindex="-1" style="color: #000">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Reviews</h5>
        <button id="closeReviewsBtn" type="button" class="btn-close" data-bs-dismiss="modal"
          aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row" *ngIf="!loadingReviews">
          <div *ngFor="let review of reviews" class="col-12">
            <app-tour-review [review]="review"></app-tour-review>
            <hr />
          </div>
        </div>
        <div class="row" style="height: 90vh; width: 100%" *ngIf="loadingReviews">
          <div class="loader" style="position: absolute; top: 50%; right: 50%"></div>
        </div>
      </div>
    </div>
  </div>
</div>