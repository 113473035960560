<div class="container">
  <div class="row">
    <div class="col-12 mainContent">
      <h1>AllTours Partnership Program - Terms and Conditions</h1>
      <p>Welcome to the AllTours Partnership Program. By signing up for the program, you agree to the following terms
        and conditions. Please read them carefully.</p>

      <h2>1. Eligibility</h2>
      <p>You must be 18 years or older to participate in the AllTours Partnership Program. By joining the program, you
        agree that all information you provide to us is accurate and up to date. Any misrepresentation of information
        may result in termination of your account and forfeiture of any earnings.</p>

      <h2>2. Partnership Program Overview</h2>
      <p>The AllTours Partnership Program allows you to promote AllTours tours and subscriptions through provided
        marketing materials, including a unique QR code or referral link. You will earn commissions on sales generated
        through your QR code or referral link.</p>

      <h2>3. Commissions</h2>
      <div>
        You will earn:
        <ul>
          <li>40% commission on any tour purchased through your QR code or referral link.</li>
          <li>30% commission on any first subscription payment made by a user referred by you.</li>
        </ul>
        <p>
          Commissions are only valid for new users who have not previously used AllTours and must be generated within
          two
          weeks of the user's first visit through your referral link or QR code. Commissions will not be earned for any
          purchases made after this 2-week period.
        </p>
      </div>

      <h2>4. Payment Terms</h2>
      <p>Payments will be made on a weekly basis, every Friday, with no minimum earning threshold. Payments will be
        processed via the payment method you provided at sign-up. If there are any issues with your payment method, you
        must resolve them to continue receiving your commissions.</p>

      <h2>5. Termination</h2>
      <p>AllTours reserves the right to terminate your participation in the Partnership Program at any time, with or
        without cause. If terminated, you will no longer be eligible to earn commissions on referrals made after the
        termination date. Any outstanding commission earned prior to termination will be paid on the next scheduled
        payout date.</p>

      <h2>6. Marketing Material</h2>
      <p>You agree to only use the official AllTours marketing materials provided to you, including posters with QR
        codes and digital assets. You are not permitted to alter the materials in any way without prior written consent
        from AllTours. Unauthorized use or modification of these materials may result in the termination of your
        account.</p>

      <h2>7. Responsibility and Liability</h2>
      <p>AllTours is not responsible for any technical issues, delays, or interruptions that may prevent referrals from
        being tracked properly. You agree to use the Partnership Program responsibly and in accordance with all
        applicable laws. AllTours will not be held liable for any lost earnings due to technical malfunctions or errors
        outside of its control.</p>

      <h2>8. Intellectual Property</h2>
      <p>All intellectual property rights to the marketing materials, QR codes, and the AllTours brand remain the
        property of AllTours. You are granted a limited license to use these materials solely for the purpose of
        promoting AllTours. You are not permitted to create or distribute derivative works based on these materials.</p>

      <h2>9. Confidentiality</h2>
      <p>Any confidential information that you receive as part of the Partnership Program, including but not limited to
        payment details, commission structures, and customer data, must be kept confidential and not disclosed to any
        third parties. Breach of this confidentiality may result in termination of your participation in the program and
        legal action.</p>

      <h2>10. Modification of Terms</h2>
      <p>AllTours reserves the right to modify these terms and conditions at any time. You will be notified of any
        changes, and continued participation in the Partnership Program will constitute your acceptance of the modified
        terms. If you do not agree to the new terms, you may terminate your participation in the program.</p>

      <h2>11. Governing Law</h2>
      <p>These terms and conditions are governed by and construed in accordance with the laws of [Your Jurisdiction].
        Any disputes arising from the Partnership Program will be subject to the exclusive jurisdiction of the courts of
        [Your Jurisdiction].</p>

      <h2>12. Contact Information</h2>
      <p>If you have any questions about these Terms and Conditions, please <a routerLink="/contact">contact Kes</a>,
        the founder of AllTours. We are always here to assist you with any issues or inquiries you may have.</p>
    </div>
  </div>
</div>