<div style="margin: auto; max-width: 500px;">
  <div class="form-group position-relative">
    <div class="input-group">
      <input id="searchInput" style="height: 48px;" placeholder="Search here" type="text" class="form-control"
        autocomplete="off">
      <button type="button" class="btn btn-secondary" style="background-color: #3b4d00;">
        <i *ngIf="!loading" class="fa fa-search"></i>
        <div *ngIf="loading" style="width: 16px; height: 16px;" class="loader"></div>
      </button>
    </div>
    <div *ngIf="results != undefined && results.length > 0" class="dropdown-menu show" style="width: 100%">
      <a *ngFor="let result of results" routerLink="{{ result.path }}" class="dropdown-item searchResultRow"
        style="display: flex;">
        <div class="searchIcon">
          <div *ngIf="result.type === 'tour'">
            <i class="fa fa-headphones"></i>
          </div>
          <div *ngIf="result.type === 'city'">
            <i class="fa fa-city"></i>
          </div>
        </div>
        <div class="searchResultText">
          {{ result.title }}<span *ngIf="result.type == 'city'">, {{ result.description }}</span>
        </div>
      </a>
    </div>
    <div *ngIf="results != undefined && results.length === 0 && !loading && query.length > 0" class="dropdown-menu show"
      style="width: 100%">
      <div class="dropdown-item searchResultRow">
        <div class="searchResultText" style="text-align: center;">
          No results found<br />try searching for a city
        </div>
      </div>
    </div>
    <div *ngIf="loading" class="dropdown-menu show" style="width: 100%">
      <div class="dropdown-item searchResultRow">
        <div class="searchResultText" style="text-align: center;">
          Searching for results...
        </div>
      </div>
    </div>
    <div *ngIf="results == undefined" class="dropdown-menu show" style="width: 100%">
      <div class="dropdown-item searchResultRow">
        <div class="searchResultText" style="text-align: center;">
          Sorry, there was an error during your search 😢
        </div>
      </div>
    </div>
  </div>
</div>