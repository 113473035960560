import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import firebase from 'firebase';

@Component({
  selector: 'app-admin-editor',
  templateUrl: './admin-editor.component.html',
  styleUrls: ['./admin-editor.component.css', '../../../app.component.css'],
})
export class AdminEditorComponent implements OnInit {
  loading = true;
  lang;
  tourId;
  tour;
  originalTour;
  query;
  audio = new Audio();

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.lang = this.route.snapshot.paramMap.get('lang');
    this.tourId = this.route.snapshot.paramMap.get('tourId');
    this.getTour();
  }

  getTour() {
    this.query =
      this.lang == undefined ? `guided-tours/${this.tourId}` : `guided-tours/${this.tourId}/translations/${this.lang}`;
    firebase
      .firestore()
      .doc(this.query)
      .get()
      .then(querySnapshot => {
        this.tour = querySnapshot.data();
        this.originalTour = { ...this.tour };
        this.loading = false;
        console.log('tour', this.tour);
      });
  }

  async playAudio(landmark) {
    if (this.audio.src == landmark.audioUrl && this.audio.paused) {
      await this.audio.play();
    } else if (this.audio.src == landmark.audioUrl && !this.audio.paused) {
      this.audio.pause();
    } else {
      this.audio.src = landmark.audioUrl;
      this.audio.load();
      await this.audio.play();
    }
  }

  hasChanged() {
    return JSON.stringify(this.tour) != JSON.stringify(this.originalTour);
  }

  async updateTour() {}
}
