<div style="display: flex">
  <div style="position: absolute; width: 100%">
    <img style="width: 100%; height: 36vh; object-fit: cover"
      src="../../../assets/images/alltours/screenshots/audio.jpg" />
  </div>
  <div class="imgCover" style="height: 36vh"></div>
  <div class="container ontopCover">
    <div class="row">
      <nav class="col-12" aria-label="breadcrumb">
        <ol class="breadcrumb" style="background-color: #0000">
          <li class="breadcrumb-item">
            <a class="bread-link text-capitalize" routerLink="">{{ strings.home }}</a>
          </li>
          <li class="breadcrumb-item">
            <a class="bread-link text-capitalize" routerLink="/tour/{{ countryName.toLowerCase() }}">
              {{ countryName}}
            </a>
          </li>
          <li class="breadcrumb-item text-capitalize" aria-current="page" *ngIf="city != undefined">
            {{ city.names[language] }}
          </li>
        </ol>
      </nav>
    </div>
    <div class="cityLanding row">
      <div>
        <div class="col-12">
          <h1 class="title text-center">{{ title }}</h1>
        </div>
        <div class="col-12">
          <p style="font-size: 16px; font-weight: 500; color: #ffffffcc">{{ subTitle }}</p>
        </div>
        <div *ngIf="!loadingTours && tours.length == 0" class="col-12">
          <button type="button" (click)="goToApp()" class="ctaBtn">
            {{ strings.get_alltours }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div style="background: #fafafa; color: #000000aa">
  <div class="container" style="position: relative">
    <div class="row" *ngIf="loadingTours">
      <div class="col-12 col-md-6 col-xl-4 tourPreviewOuterContainer" *ngFor="let number of [0,1,2]">
        <app-tour-preview [tour]="undefined"></app-tour-preview>
      </div>
    </div>
    <div class="row" [@fadeInUp]="loadingTours ? 'out' : 'in'">
      <div class="col-12 col-md-6 col-xl-4 tourPreviewOuterContainer" *ngFor="let tour of tours">
        <app-tour-preview [tour]="tour"></app-tour-preview>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div id="article" class="card" style="min-height:100vh; padding:32px; margin-top: 64px;" [innerHtml]="article">

        </div>
      </div>
      <div class="col-12" style="text-align: center">
        <button type="button" (click)="goToApp()" style="margin: 64px; background:#3B4D00" class="ctaBtnMini">
          {{strings.get_alltours}}
        </button>
      </div>
    </div>
  </div>
</div>
<div [innerHTML]="jsonLD"></div>