import { Component, OnInit } from '@angular/core';
import { PartnerService } from '../../partner-service';

@Component({
  selector: 'app-partner-marketing-dashboard',
  templateUrl: './partner-marketing-dashboard.component.html',
  styleUrls: ['./partner-marketing-dashboard.component.css', '../../../../app.component.css'],
})
export class PartnerMarketingDashboardComponent implements OnInit {
  constructor(
    public partnerService: PartnerService
  ) { }

  assets = [];

  ngOnInit() {
    this.assets = this.posters;
  }

  copyLink(link) {
    navigator.clipboard.writeText(link);
  }

  posters = [
    {
      id: 1,
      title: 'Poster #1',
      image: '../../../../../assets/images/alltours/poster1.png',
    },
  ];
}
