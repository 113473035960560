import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import citiesJson from '../../../../assets/json/mini_cities.json';
import topCitiesJson from '../../../../assets/json/top_tourist_cities.json';
import firebase from 'firebase';
import { EventEmitter, Output } from '@angular/core';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import stringsJson from '../../../../assets/strings/strings.json';
import { stringFormat } from '../../../utils/utils';
import exampleTour from '../../../../assets/json/example_tour.json';
import { fadeInUpAnimation, fadeAnimation } from 'src/app/utils/animations';

const db = firebase.firestore();

@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.css', '../../../app.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [fadeInUpAnimation, fadeAnimation]
})
export class CityComponent implements OnInit {
  jsonLD: SafeHtml;

  @Output() onOpenApp = new EventEmitter();

  constructor(
    @Inject(MatDialog) public dialog: MatDialog,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { }

  cities = citiesJson.cities;
  countryName;
  city;
  article;
  strings;
  title;
  subTitle;
  defaultArticle;
  language;
  tours = [];
  showCta1 = false;
  loadingTours = true;

  ngOnInit() {
    this.language = window.location.hostname.split(".")[0]
    if (this.language == undefined || this.language.length > 2) this.language = "en"
    this.strings = JSON.parse(JSON.stringify(stringsJson))["default"][this.language]
    this.countryName = this.route.snapshot.paramMap.get("countryName")
    this.getCity()
    if (this.city != undefined) {
      this.title = stringFormat(this.strings.city_audio_tours_in_language, this.city.names[this.language])
      this.subTitle = this.strings.explore_at_own_pace
      this.defaultArticle = this.strings.default_article
      this.setSeoText()
      this.getArticle()
      this.getTours()
    }
  }

  getCity() {
    let cityName = this.route.snapshot.paramMap.get("cityName").toLowerCase()
    this.city = this.cities.find((c) =>
      (c.names[this.language].toLowerCase() == cityName || c.name.toLowerCase() == cityName)
      && c.country.toLowerCase() == this.countryName.toLowerCase()
    )
    if (this.city == undefined) {
      this.city = this.cities.find((c) =>
        (c.names[this.language].toLowerCase() == cityName || c.name.toLowerCase() == cityName)
      )
    }
  }

  getTours() {
    console.log('getTours', this.language, this.city.id);
    this.loadingTours = true;
    firebase
      .firestore()
      .collection('guided-tours')
      .where('city.id', '==', parseInt(this.city.id))
      .where('landmarkCount', '>', 4)
      .limit(10)
      .get()
      .then(querySnapshot => {
        console.log('docs', querySnapshot.docs);
        querySnapshot.docs.forEach(doc => {
          this.tours.push(doc.data());
        });
        this.tours.sort((a, b) => {
          const ratingA = a.ratings?.ratingSum ?? 0;
          const ratingB = b.ratings?.ratingSum ?? 0;
          return ratingB - ratingA
        });

        this.loadingTours = false;
      })
      .catch(error => {
        console.log('Error getting documents: ', error);
        this.loadingTours = false;
      });
  }

  setSeoText() {
    let baseUrl = `https://alltours.app/${this.language}/alltours`;
    document.title = this.title;
    document.head.children.namedItem('description')['content'] = stringFormat(
      this.strings.city_sub_title,
      this.city.names[this.language]
    );
    var link = document.createElement('link');
    link.rel = 'canonical';
    // link.href =
    document.head.appendChild(link);

    console.log('url', baseUrl);
    let breadcrumbs = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: [
        {
          '@type': 'ListItem',
          position: 1,
          name: this.strings.home,
          item: `${baseUrl}/home`,
        },
        {
          '@type': 'ListItem',
          position: 2,
          name: this.strings.cities,
          item: `${baseUrl}/city`,
        },
        {
          '@type': 'ListItem',
          position: 3,
          name: this.city.names[this.language],
        },
      ],
    };
    this.jsonLD = this.getSafeHTML(breadcrumbs);
  }

  getSafeHTML(value: {}) {
    const json = JSON.stringify(value, null, 2);
    const html = `<script type="application/ld+json">${json}</script>`;
    // Inject to inner html without Angular stripping out content
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  async getArticle() {
    var data = null;
    if (topCitiesJson.cities.find(c => c.id == this.city.id) != null) {
      try {
        data = (
          await firebase.functions().httpsCallable('getAllToursArticle', { timeout: 4000 })({
            language: this.language,
            cityId: this.city.id,
          })
        ).data;
      } catch (e) {
        console.error(e);
        data = null;
      }
    }
    if (data == undefined || data == null) {
      this.article = this.defaultArticle;
    } else {
      this.article = data.replace(/<title>[^]*<\/title>/g, '');
    }
  }

  goToApp() {
    if (navigator.userAgent.match(/Mac|iPhone|iPad|iPod/i)) {
      firebase.analytics().logEvent('alltours_cta_click', { type: 'ios' });
      window.open('https://apps.apple.com/app/alltours-audio-tours/id6475402833?platform=iphone');
    } else {
      firebase.analytics().logEvent('alltours_cta_click', { type: 'not-ios' });
      window.open('https://play.google.com/store/apps/details?id=app.alltours');
    }
  }
}
