<nav style="box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2)"
  class="navbar sticky-top navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="">
      <div style="display: flex">
        <img id="landingIcon" src="../../assets/images/alltours/logo.png" />
        <span id="landingText">AllTours</span>
      </div>
    </a>
    <div class="navbar" id="navbarNav" style="padding: 0;">
      <ul class="navbar-nav me-auto" style="flex-direction: row;">
        <li class="nav-item" style="margin-right: 16px">
          <button class="loginBtn btn" routerLink="/become-a-partner/login">Login</button>
        </li>
        <li class="nav-item">
          <button class="createAccountBtn btn" style="background: #daff5e; color: #000; border: solid 1px;"
            routerLink="/become-a-partner/signup">Become A Partner</button>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div style="color: #000; background-color: #fafafa; min-height: 95vh">
  <router-outlet></router-outlet>
</div>