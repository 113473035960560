import { Component, OnInit } from '@angular/core';
import stringsJson from '../../../assets/strings/strings.json';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from './user.service';
import { PartnerService } from '../partner/partner-service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css', '../../app.component.css'],
})
export class UserComponent implements OnInit {

  languages = [
    { id: 'en', name: 'English' },
    { id: 'es', name: 'Español' },
    { id: 'fr', name: 'Français' },
    { id: 'zh', name: '中国人' },
    { id: 'de', name: 'Deutsch' },
    { id: 'it', name: 'Italiano' },
    { id: 'ja', name: '日本語' },
    { id: 'pt', name: 'Português' },
  ];

  language;
  strings;
  lang;

  constructor(public router: Router, private route: ActivatedRoute, public userService: UserService, public partnerService: PartnerService) {
    this.language = window.location.hostname.split('.')[0];
    if (this.language == undefined || this.language.length > 2) this.language = 'en';
    this.strings = JSON.parse(JSON.stringify(stringsJson))['default'][this.language];
    this.lang = this.languages.find(l => l.id == this.language);
  }

  onLanguageChange(val) {
    console.log(val);
    let newLanguage = this.languages.find(l => l.name == val);
    let newUrl = this.router.url.replace(this.language, newLanguage.id);
    this.router.navigate([newUrl]);
    console.log(val);
  }

  ngOnInit() {
    this.getPartnerId()
  }

  getPartnerId() {
    const partnerId = this.route.snapshot.queryParams['partner'];
    if (partnerId) {
      this.userService.setPartnerId(partnerId);
    }
  }

}
