<div class="container editorContainer">
  <div class="row">
    <div id="posterEditorContainer" class="col-12 col-xl-4" style="background: #fff;">
      <div style="width: 100%; padding: 5%">
        <h5 style="font-weight: 700;">Poster Editor</h5>
        <div>
          <form *ngIf="partnerService.partner$ | async as partner">
            <div class="mb-3">
              <label for="posterId" class="form-label">Poster #</label>
              <select id="posterId" name="posterId" class="form-control" (change)="onPosterChange($event)"
                [(ngModel)]="posterId">
                <option *ngFor="let id of posterIds" value="{{id}}">{{ id }}</option>
              </select>
            </div>
            <div class="mb-3">
              <label for="city" class="form-label">City</label>
              <select id="city" name="city" class="form-control" (change)="onCityChange($event)">
                <option *ngFor="let city of partner.cities" value="{{city.id}}">{{city.name}}</option>
              </select>
            </div>
          </form>
          <div class="buttonContainer">
            <div style="display: flex; width: 100%">
              <div style="margin: auto;">
                <button *ngIf="needsChromePrintHint" class="ctaBtnMini2" data-bs-toggle="modal"
                  data-bs-target="#chromePrintHint">Print Poster</button>
                <button *ngIf="!needsChromePrintHint" class="ctaBtnMini2" (click)="printPage()">Print Poster</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="posterContent == undefined" class="col-12 col-xl-8" style="padding: 25%">
      <div style="margin: auto; text-align: center;">
        <div class="loader" style="margin: auto;"></div>
        <p style="margin-top: 8px;">Loading...</p>
      </div>
    </div>
    <div class="posterContainer col-12 col-xl-8" *ngIf="posterContent != undefined">
      <app-partner-poster-1 *ngIf="posterId == 1" [posterContent]="posterContent"></app-partner-poster-1>
      <app-partner-poster-2 *ngIf="posterId == 2" [posterContent]="posterContent"></app-partner-poster-2>
    </div>
  </div>
</div>


<div id="chromePrintHint" class="modal" tabindex="-1" style="color: #000">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Printing advice</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body" style="color: #000a">
        <p style="margin-top: 32px; margin-bottom: 48px; font-size: 20px;">
          For best results, please TICK 'Background graphics' before printing
        </p>
        <img src="../../../../../assets/images/alltours/chromePrintHint.png" style="width: 100%" />
      </div>
      <div class="modal-footer">
        <button type="button" (click)="printPage()" class="ctaBtnMini" data-bs-dismiss="modal">Ok, Print Now</button>
      </div>
    </div>
  </div>
</div>