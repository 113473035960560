<div style="display: flex">
  <div style="width: 100%">
    <img style="width: 100%; height: 50vh; object-fit: cover" src="../../../assets/images/alltours/london.jpg" />
  </div>
  <div class="imgCover" style="height: 50vh">
    <div class="container" style="height: 100%">
      <div class="row" style="position: absolute">
        <nav class="col-12" aria-label="breadcrumb">
          <ol class="breadcrumb" style="background-color: #0000">
            <li class="breadcrumb-item">
              <a class="bread-link" routerLink="">{{ strings.home }}</a>
            </li>
            <li class="breadcrumb-item" aria-current="page">{{ breadcrumbName }}</li>
          </ol>
        </nav>
      </div>
      <div class="row" style="height: 100%">
        <div class="col-lg-6 col-md-12" style="align-self: center">
          <h1>{{ strings.create_tours_in_cities }}</h1>
        </div>
        <div class="col-lg-6 cities-img">
          <img style="height: 50vh" src="../../../assets/images/alltours/screenshots/city_hero.png" alt="Logo" />
        </div>
      </div>
    </div>
  </div>
</div>
<div style="background: #fff; min-height: 40vh">
  <div class="container" style="color: #595959">
    <div class="row">
      <div class="col-12" style="display: flex">
        <img
          *ngIf="selectedCountry != undefined"
          src="../../../assets/flags/{{ selectedCountry }}.png"
          class="country-img-title" />
        <h3 style="padding-top: 16px; padding-bottom: 16px">{{ title }}</h3>
      </div>
      <div *ngFor="let city of cities" class="col-lg-2 col-md-3 col-sm-4 col-6">
        <a
          class="city-name"
          routerLink="/tour/{{ countryNames[city.countryCode][language].toLowerCase() }}/{{
            city.names[language].toLowerCase()
          }}"
          >{{ city.names[language] }}</a
        >
      </div>
      <div *ngIf="countries != undefined" class="col-12">
        <h3 style="padding-top: 16px; padding-bottom: 16px">{{ strings.top_countries }}</h3>
      </div>
      <div *ngFor="let group of countries" class="col-lg-3 col-md-4 col-6 country">
        <img class="country-img" src="../../../assets/flags/{{ group[0].countryCode.toLowerCase() }}.png" />
        <div style="margin-left: 8px">
          <a routerLink="/tour/{{ countryNames[group[0].countryCode][language].toLowerCase() }}">{{
            countryNames[group[0].countryCode][language]
          }}</a
          ><br />
          <span>{{ group.length }} {{ strings.cities }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
